<footer>
    <div class="container-fluid">
        <div class="row bg-dark py-5 detail">
            <div class="col-12 mobile-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="mb-3">
                                <img src="../../../../assets/images/logo-light.svg" width="104" height="32" alt="logo">
                            </div>
                            <p class="text-hint info p3">
                                iTAX เกิดจากงานวิจัยปริญญาเอก ด้วยความเชื่อว่าผู้เสียภาษี คือฮีโร่ตัวจริงของประเทศนี้
                                เราจึงพัฒนาเทคโนโลยีที่ทำให้ภาษีเป็นเรื่องง่ายที่สุดสำหรับทุกคน
                                เพราะนี่คือสิ่งที่ผู้เสียภาษีสมควรได้รับ
                            </p>
                        </div>
                        <div class="col-6 col-lg-2 small">
                            <div class="row pb-30px">
                                <div class="col-12">
                                    <div class="group-link">{{ personalItem.label }}</div>
                                    <ul class="list-unstyled">
                                        <li *ngFor="let item of personalItem.items" class="text-darker">
                                            <a href="{{item.link}}" class="text-hint">{{ item.label }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row pb-30px">
                                <div class="col-12">
                                    <div class="group-link">{{ coperateItem.label }}</div>
                                    <ul class="list-unstyled">
                                        <li *ngFor="let item of coperateItem.items">
                                            <a href="{{item.link}}" class="text-hint">{{ item.label }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row pb-30px mobile">
                                <div class="col-12 padding">
                                    <div class="group-link">{{ articleItem.label }}</div>
                                    <ul class="list-unstyled">
                                        <li *ngFor="let item of articleItem.items">
                                            <a href="{{item.link}}" class="text-hint">{{ item.label }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-2 small">
                            <div class="row pb-30px">
                                <div class="col-12">
                                    <div class="group-link">{{ taxItem.label }}</div>
                                    <ul class="list-unstyled">
                                        <li *ngFor="let item of taxItem.items">
                                            <a href="{{item.link}}" class="text-hint">{{ item.label }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row pb-30px mobile">
                                <div class="col-12 padding">
                                    <div class="group-link">{{ iTaxItem.label }}</div>
                                    <ul class="list-unstyled">
                                        <li *ngFor="let item of iTaxItem.items">
                                            <a href="{{item.link}}" class="text-hint">{{ item.label }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-2 small">
                            <div class="row pb-30px desktop">
                                <div class="col-12">
                                    <div class="group-link">{{ articleItem.label }}</div>
                                    <ul class="list-unstyled">
                                        <li *ngFor="let item of articleItem.items">
                                            <a href="{{item.link}}" class="text-hint">{{ item.label }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row pb-30px desktop">
                                <div class="col-12">
                                    <div class="group-link">{{ iTaxItem.label }}</div>
                                    <ul class="list-unstyled">
                                        <li *ngFor="let item of iTaxItem.items">
                                            <a href="{{item.link}}" class="text-hint">{{ item.label }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row bg-darkest py-3 d-flex justify-content-center">
            <div class="wrap-copy">
                <div class="container w-100">
                    <div class="d-flex justify-content-between w-100 text-center copy">
                        <span class="text-hint small">
                            Copyright © {{ copyrightYear }} iTAX Inc. All rights reserved.
                        </span>
                        <div class="social-logo">
                            <a href="https://www.facebook.com/itaxthailand" class="mr-4">
                                <img src="../../../../assets/images/facebook.svg" width="20" height="20" alt="facebook">
                            </a>
                            <a href="https://twitter.com/itaxthailand">
                                <img src="../../../../assets/images/twitter.svg" width="20" height="20" alt="twitter">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
