<div class="nav-overlay" id="nav-overlay" data-toggle="collapse" data-target="#collapsingNavbar"
     (click)="openOverlay(display)"></div>
<div class="bg-light fixed-top">
    <nav class="navbar navbar-light container navbar-expand-lg nav-height">
        <a class="navbar-brand pr-40px mr-0" href="/">
            <img src="../../../../assets/images/logo.svg" width="104" height="32" alt="logo">
        </a>
        <div class="px-3 h-100 user-details d-lg-none d-xl-none">
            <div class="navbar-toggler d-inline-flex d-lg-none d-xl-none" data-toggle="collapse"
                 data-target="#collapsingNavbar">
                <div class="d-inline-flex px-3 py-1 h-100">
                    <i class="fas fa-bars" (click)="openOverlay(display)"></i>
                </div>
            </div>
        </div>
        <div class="collapse navbar-collapse" id="collapsingNavbar">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item" *ngFor="let item of navbarItems">
                    <a class="nav-link px-lg-0 mr-lg-30px w-100 text-center" href="{{item.link}}" id="{{item.id}}"
                       [ngClass]="{'active': isActive(item.link)}">
                        {{ item.label }}
                    </a>
                </li>
                <!--                <div class="line"></div>-->
                <!--                <li class="d-lg-none d-xl-none">-->
                <!--                    <a class="nav-item nav-link" href="#">เข้าสู่ระบบ</a>-->
                <!--                </li>-->
                <!--                <li class="d-lg-none d-xl-none">-->
                <!--                    <a class="nav-item nav-link" href="#">สมัครฟรี</a>-->
                <!--                </li>-->
            </ul>
            <!--            <a class="nav-item nav-link d-none d-lg-block d-xl-block px-0 mr-30px" href="#">เข้าสู่ระบบ</a>-->
            <!--            <button class="btn btn-outline-success d-none d-lg-block d-xl-block" type="button">สมัครฟรี</button>-->
        </div>
    </nav>
</div>
