<div class="app-banner"  *ngIf="showBanner">
  <div class="content-box">
    <div class="content">
      <img src="../../../../assets/images/appicon.svg" alt="itax app icon" width="60" height="60">
      <div class="text-content">
        <div>
          iTAX คำนวณและวางแผนภาษี
        </div>
        <div>
          <img src="../../../../assets/images/star.svg" class="star" alt="star" width="10" height="10">
          <img src="../../../../assets/images/star.svg" class="star" alt="star" width="10" height="10">
          <img src="../../../../assets/images/star.svg" class="star" alt="star" width="10" height="10">
          <img src="../../../../assets/images/star.svg" class="star" alt="star" width="10" height="10">
          <img src="../../../../assets/images/star.svg" alt="star" width="10" height="10">
        </div>
        <div>
          (100K+)
        </div>
    </div>
    </div>
    <a href="https://bit.ly/2ugrUeM">
      <button class="download-button">
        ดาวน์โหลดฟรี
      </button>
    </a>
  </div>
</div>
